import React from 'react';

import './events.css';

const Events = () => {
    return (
        <div class="eventsPage page">
            <h1 class="title">Upcoming Events</h1>
        </div>
    );
};

export default Events;