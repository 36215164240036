import React from 'react';

import './products.css';

const Products = () => {
    return (
        <div class="productsPage page">
            <h1 class="title">Products</h1>
        </div>
    );
};

export default Products;